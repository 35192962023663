import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import '@fontsource/poppins/latin-400.css';
import '@fontsource/poppins/latin-500.css';
import '@fontsource/poppins/latin-600.css';
import theme from './src/theme';
import { Provider as FormStateProvider } from './src/hooks/useFormContext';

// eslint-disable-next-line
import 'swiper/css/bundle';

export const wrapRootElement = ({ element }) => {
  return (
    <FormStateProvider>
      <SnackbarProvider autoHideDuration={10000}>{element}</SnackbarProvider>
    </FormStateProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  );
};
