import palette from '../palette';

const MuiFormControlLabel = {
  styleOverrides: {
    label: {
      color: palette.text.secondary,
    },
  },
};

export default MuiFormControlLabel;
