const white = '#FFFFFF';
const black = '#000000';

const palette = {
  black,
  white,
  primary: {
    contrastText: white,
    main: '#8c64d2',
    light: '#F5EFFD',
    dark: '#282146',
  },
  secondary: {
    contrastText: white,
    main: '#7bae37',
  },
  error: {
    contrastText: white,
    main: '#ff4242',
  },
  warning: {
    contrastText: white,
    main: '#FFCC00',
  },
  info: {
    contrastText: white,
    main: '#2D8FD5',
  },
  success: {
    contrastText: white,
    main: '#f1fbf1',
  },
  text: {
    primary: '#110f14',
    secondary: '#5f5a69',
    placeholder: '#5f6368',
    link: '#008EED',
  },
  background: {
    default: white,
    paper: white,
    block: '#f7f7f7',
  },
  divider: '#DADCE0',
};
export default palette;
