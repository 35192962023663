import { createTheme, responsiveFontSizes } from '@mui/material';
import palette from './palette';
import typography from './typography';
import components from './components';
import breakpoints from './breakpoints';
import shadows from './shadows';

const theme = createTheme({
  components,
  palette,
  typography,
  breakpoints,
  shadows,
  spacing: 8,
  shape: {
    borderRadius: 6,
  },
  topBarHeight: 100,
  mobileTopBarHeight: 40,
});

export default responsiveFontSizes(theme);
