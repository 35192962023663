import palette from '../palette';

const MuiCheckbox = {
  styleOverrides: {
    root: {
      color: palette.divider,
    },
  },
};

export default MuiCheckbox;
