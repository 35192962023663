import { createTheme } from '@mui/material';
import palette from '../palette';

const defaultTheme = createTheme();

const MuiButton = {
  styleOverrides: {
    root: {
      borderRadius: '20px',
      padding: '15px 20px',
      fontWeight: 500,
      transition: defaultTheme.transitions.create(['background-color', 'box-shadow', 'border', 'color'], {
        duration: defaultTheme.transitions.duration.short,
      }),
      [defaultTheme.breakpoints.down('md')]: {
        padding: '10px 20px',
      },
    },
    colorInherit: {
      background: palette.background.block,
    },
    sizeSmall: {
      padding: '10px 20px',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '0.675rem',
        padding: '4px 8px',
      },
    },
    sizeLarge: {
      minWidth: 64,
      minHeight: 48,
      padding: '20px 30px',
      fontSize: '1rem',
      [defaultTheme.breakpoints.down('md')]: {
        padding: '14px 30px',
      },
    },
    contained: {
      fontSize: '1rem',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    text: {
      padding: '10px 0 !important',
      '&:hover': {
        color: palette.primary.main,
        background: 'transparent',
      },
    },
    startIcon: {
      marginRight: '5px',
    },
    endIcon: {
      marginLeft: '5px',
    },
  },
};

export default MuiButton;
