const MuiOutlinedInput = {
  styleOverrides: {
    input: {
      padding: '14.5px 14px',
    },
    notchedOutline: {
      borderColor: '#ecebea',
    },
  },
};

export default MuiOutlinedInput;
