module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-1700feeb8b/0/cache/gatsby-plugin-manifest-npm-4.25.0-25ef1b75df-a9c755d0e5.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mikra - Web zahtjev za kredit","short_name":"Mikra Web Zahtjev","start_url":"/","background_color":"#8c64d2","theme_color":"#8c64d2","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"6868c27d09a92d43a32b247653e6fa83"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-cdb162353e/0/cache/gatsby-plugin-google-tagmanager-npm-4.25.0-f7c88884e6-e2804b450c.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W2NKNRJ","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-virtual-f89855c6d4/0/cache/gatsby-npm-4.25.1-0bb37c31fb-4813ca1795.zip/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
