const MuiFormHelperText = {
  styleOverrides: {
    root: {
      fontSize: '0.75rem',
      fontWeight: '500',
    },
  },
};

export default MuiFormHelperText;
