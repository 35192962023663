import palette from '../palette';

const MuiLink = {
  styleOverrides: {
    root: {
      color: palette.text.link,
      fontWeight: 500,
    },
  },
};

export default MuiLink;
