import React, { createContext, useContext, useReducer } from 'react';

const Context = createContext({});

export const initialState = {
  amount: 1400,
  repaymentPeriod: 20,
  loanApplicationId: null,
};

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer((state, payload) => {
    if (payload.reset) return initialState;
    return { ...state, ...payload };
  }, initialState);
  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

const useFormContext = () => useContext(Context);

export default useFormContext;
