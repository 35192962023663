exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aplikacija-za-finansiranje-js": () => import("./../../../src/pages/aplikacija-za-finansiranje.js" /* webpackChunkName: "component---src-pages-aplikacija-za-finansiranje-js" */),
  "component---src-pages-aplikacija-za-kooperante-js": () => import("./../../../src/pages/aplikacija-za-kooperante.js" /* webpackChunkName: "component---src-pages-aplikacija-za-kooperante-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontaktirajte-nas-js": () => import("./../../../src/pages/kontaktirajte-nas.js" /* webpackChunkName: "component---src-pages-kontaktirajte-nas-js" */),
  "component---src-pages-mikra-to-smo-mi-js": () => import("./../../../src/pages/mikra-to-smo-mi.js" /* webpackChunkName: "component---src-pages-mikra-to-smo-mi-js" */),
  "component---src-pages-novosti-js": () => import("./../../../src/pages/novosti.js" /* webpackChunkName: "component---src-pages-novosti-js" */),
  "component---src-pages-pitanja-i-odgovori-js": () => import("./../../../src/pages/pitanja-i-odgovori.js" /* webpackChunkName: "component---src-pages-pitanja-i-odgovori-js" */),
  "component---src-pages-prigovori-pohvale-sugestije-js": () => import("./../../../src/pages/prigovori-pohvale-sugestije.js" /* webpackChunkName: "component---src-pages-prigovori-pohvale-sugestije-js" */),
  "component---src-pages-vrste-finansijskih-proizvoda-js": () => import("./../../../src/pages/vrste-finansijskih-proizvoda.js" /* webpackChunkName: "component---src-pages-vrste-finansijskih-proizvoda-js" */),
  "component---src-pages-zahtjev-primljen-js": () => import("./../../../src/pages/zahtjev-primljen.js" /* webpackChunkName: "component---src-pages-zahtjev-primljen-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

