import { createTheme } from '@mui/material';

const defaultTheme = createTheme();

const MuiTypography = {
  styleOverrides: {
    gutterBottom: {
      marginBottom: defaultTheme.spacing(2),
    },
  },
};

export default MuiTypography;
