import { createTheme } from '@mui/material';

const defaultTheme = createTheme();

const shadows = [
  'rgba(149, 157, 165, 0.08) 0px 8px 16px',
  '0 2.9px 4.7px rgba(0, 0, 0, 0.011), 0 8px 12.9px rgba(0, 0, 0, 0.01), 0 19.3px 31.1px rgba(0, 0, 0, 0.018), 0 64px 103px rgba(0, 0, 0, 0.03)',
  '0px 11px 15px -7px rgb(65 65 139 / 8%), 0px 24px 38px 3px rgb(65 65 139 / 6%), 0px 9px 46px 8px rgb(65 65 139 / 4%)',
  ...defaultTheme.shadows.slice(2),
];

export default shadows;
